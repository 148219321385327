import React from 'react';
import styles from '../../../../styles/modules/components/homecare/selection.module.scss';
import HomecareCard from './HomecareCard';
import {getStaticPath} from '../../../../utils';


const HomecarePlans = (props:any) => {
  const [hover, set_hover] = React.useState<number | null>(null)
  const [small_screen,setLyaout] = React.useState<boolean>(true);

  const setMousePosition = (index:any) => {
    set_hover(index)
  }

  const resetMousePosition = () => {
    set_hover(null)
  }

  const formatProducts = (products) => {
    let formattedProducts = []
    if(props.enablePremium) {
      formattedProducts = products ? products.filter((product) => product.title !== '1 Year HomeCare Premium') : []
    } else {
      formattedProducts = products ? products.filter((product) => !product.title.toLowerCase().includes('pkg')) : []
    }
    return formattedProducts
  }


  React.useEffect(() => {
      window.innerWidth > 767 ? setLyaout(false) : setLyaout(true);
      window.addEventListener('resize',() => {
          if(window.innerWidth > 767) {
              small_screen ? setLyaout(!small_screen) : '';
          }
          else {
              !small_screen ? setLyaout(!small_screen) : '';
          }
      });
  })

  return (
    <div className={styles.homecare_plan__container}>
      {formatProducts(props.plans).map((product:any, index) => {
        return (
          <HomecareCard
            // enablePremium={props.enablePremium}
            small_screen={small_screen}
            handleEnterPhone={props.handleEnterPhone}
            handleClickCustom={props.handleClickCustom}
            plan={product}
            benefits={props.specificBenefits ? props.specificBenefits[product.description] : []}
            specificImages={props.specificImages ? getStaticPath(`/${props.specificImages[product.product_type]}`) : getStaticPath('/static/images/404.svg')}
            onMouseEnter={() => {
                setMousePosition(index)
            }}
            onMouseLeave={() => {
                resetMousePosition()
            }}
            is_hovered={hover == index}
            noOfPlans={formatProducts(props.plans).length}
          />
        )
      })}
    </div>
  )
}

export default HomecarePlans;
