import React, {Fragment} from 'react';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import Button from '../../../common/ui-components/input/Button';
import styles from '../../../../styles/modules/components/device.module.scss';
import {viewItem, viewItemList, selectItem} from '../../../../tracking/ecommerceTracking';
import {formatDate, formatNumber} from '../../../../utils/index';

interface Props {
  plans:{
    'product_name':string,
    'product_sku':string,
    'price':number,
    'mrp':number,
    'from_price':number,
    'to_price':number,
    'duration':string,
  }[],
  productType: string,
  changeSelectedPlan: (value:any, position?:number) => {},
  addToCart: (value:any) => {},
  device_price:string | number,
  editPlan:() => void,
  category:string,
  loading:boolean
}

const PlanDetails = (props:any) => {
  const [radioSelection, setRadioSelection] = React.useState<string>('option-0')

  const getEligibleDate = () => {
    let date = new Date;
    if(props.productType === 'extended-warranty-plans'){
      return formatDate(props?.plans[0]?.device_purchased.can_purchase_after, "Do MMMM YYYY")
    }
    return formatDate(date,"Do MMMM YYYY")
  }
  const transitionType = isMobile ?
  {
    pageInitial: {
      opacity:1,
      translateX:0
    },
    pageAnimate: {
      opacity:1,
      translateX:0
    }
  }
  :
  {
    pageInitial: {
      translateX:100
    },
    pageAnimate: {
      translateX:0
    }
  }
  React.useEffect(()=>{
    if(props.plans?.length!=0){
      viewItemList(props.plans)
      selectItem(props.plans[0])
      viewItem(props.plans[0])
    }
  },[props.plans])

 const ewMsg =  `: These plans only covers ${props.category} that have been purchased on or after ${getEligibleDate()}.`
 const sdpMsg =  `: These plans only covers ${props.category} that have been purchased on ${getEligibleDate()}.`

  return (
    <Fragment>
      <motion.div  className={`${styles.selection__selection} ${styles.selected} ${styles.selection_center}`} initial="pageInitial" animate="pageAnimate" variants={transitionType}>
          <h5>Plans for your device <a className={styles.device_selection__edit} onClick={(e:React.MouseEvent) => {e.preventDefault(); props.editPlan()}} href="#" title="Edit selection"></a></h5>
          <div className={styles.selection__user__selected}>
              <div className={styles.selected_block}>
                  <span className={styles.selected_block__label}>Device Price</span>
                  <span className={styles.selected_block__value}>{props.device_price ? formatNumber(props.device_price) : formatNumber(15400)}</span>
              </div>
          </div>

          <div className={styles.selection__user__selected_price}>
            {props.plans.map((plan, index) => (
                <label className={styles.selected__price_block} data-price={plan.price} data-id={plan.product_sku} htmlFor={`option-${index}`} key={`option-${index}`}>
                  <input
                    className="hidden-input"
                    type="radio"
                    checked={radioSelection===`option-${index}`}
                    name="plan-price" id={`option-${index}`}
                    onChange={() => {
                        setRadioSelection(`option-${index}`)
                        props.changeSelectedPlan(plan, index)
                      }
                    }
                  />
                  <div className={styles.selected__price_block__details}>
                    <span className={styles.price_block__original}>{plan.price === plan.mrp ? <></> : <>{formatNumber(plan.mrp)}</>}</span>
                    <span className={styles.price_block__discounted}>{formatNumber(plan.price)}</span>
                    <span className={styles.price_block__duration}>{plan.duration}</span>
                  </div>
                </label>
              ))
            }
          </div>
          <div className={styles.note}>
              {props.productType === 'extended-warranty-plans'?( <p className={styles.text}>
                  <em>Note</em>{ewMsg}
              </p>) : ( <p className={styles.text}>
                  <em>Note</em>{sdpMsg}
              </p>) }
          </div>
          <div className={styles.selection_actions}>
              <Button text="Add to Cart" variant="primary"  loading={props.loading} onClick={props.addToCart}/>
          </div>
       </motion.div>
    </Fragment>
  )
}

export default PlanDetails
