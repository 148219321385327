import React from 'react';
import {connect} from 'react-redux';

import styles from '../../../../styles/modules/components/homecare/selection.module.scss';
import {addToCart} from '../../../../redux/actions/cartActions';
import {formatDate, getUtmParams} from '../../../../utils';
import Image from '../../../common/Image';
import Button from '../../../common/ui-components/input/Button';
import { useRouter } from 'next/router'
import { formatNumber } from '../../../../utils';
import { buyNowClick } from '../../../../tracking/planPageTracking';
import { addToCartClicked } from '../../../../tracking/segment/planPage';
import { getProductName } from '../../../../utils/tracking';
import { HOMECARE_TITLES } from '../../../../constants';

const HomecareCard = (props:any) => {
  const router = useRouter()
  const addToCart = (plan:any) => {
    if(props.cart.items.find(i => i.sku === plan.sku) !== undefined) {
      router.push(`/cart/${props.cart.token_id}`)
    }
    else {
      let data = {
        type: 'homecare',
        token_id:props.cart.token_id,
        channel_name: 'website',
      };
      let item = composeItems(plan)
      // if(!props.enablePremium) {
        if(plan.description === "HomeCare Premium") {
          props.handleClickCustom()
        } else {
            data['item'] = item
            data['tracking_params'] = getUtmParams()
            addToCartClicked({ 'sku_name':plan.title, 'sku_price':plan.price, 'previous_quantity':props.cart.quantity?props.cart.quantity:0, 'quantity':props.cart.quantity?props.cart.quantity+1:0+1,'category':'HomeCare Basic', 'product_type':'HomeCare Basic', 'product_name':getProductName(plan),'product_group':'homecare'})
            props.addToCart(data);
            buyNowClick('HomeCare', plan.category)
          }
        }
      // }
      // else {
      //   if(items.length > 1){
      //     data['items'] = items
      //   } else {
      //     data['item'] = items[0]
      //   }
      //   data['tracking_params'] = getUtmParams()
      //   props.addToCart(data);
      // }
    // }
  }

  const composeItems = (plan:any) => {
    let defaultStartDate = new Date;
    defaultStartDate.setDate(defaultStartDate.getDate() + 1)
    let formatted_plan = {
      sku: plan.sku,
      quantity: 1,
      device_detail: {},
      preferred_plan_start_date: formatDate(defaultStartDate),
      is_parent: true,
      parent_id: props.cart.items.length + 1,
    }

    return formatted_plan
    // items.push(parent_plan)
    //
    // Object.keys(plan.child_products).forEach((key) => {
    //   plan.child_products[key].products.forEach(product => {
    //     items.push({
    //       sku: product.sku,
    //       quantity: 1,
    //       device_detail: {
    //         brand:null
    //       },
    //       preferred_plan_start_date: formatDate(defaultStartDate),
    //       parent_plan: props.cart.items.length + 1,
    //     })
    //   })
    // })
    // return items;
  }

  const getButtonText = (plan:any) => {
    if(props.cart.items.find(i => i.sku === plan.sku) !== undefined) {
      return "Go to Cart"
    }
    // if(!props.enablePremium) {
      if(plan.description === "HomeCare Premium") {
        return "Request a Callback"
      }
    // }
    return "Buy Now"
  }
  if(props.plan.description === "HomeCare Premium"){
    return null
  }
  const title = <h5 className={styles.homecare_plan__card_title}>{HOMECARE_TITLES[props.plan.description] || props.plan.description}</h5>;
  const features = <div className={styles.homecare__feature_list}>
    <ul className={`device_selection__feature__list`}>
      {props.benefits && props.benefits.map((item) => {
        return (
          <li>{item.text}</li>
          )
      })}
    </ul>
  </div>
  const image = <div className={styles.homecare_plan__card_image}>
    <Image className={styles.homecare_plan__card_image__inner} src={props.specificImages} width="200" height="200"/>
  </div>;
  const priceAndCta =<div className={styles.homecare_price_and_cta}>{props.plan.price !== 0 ? (
    props.plan.product_type !== 'DiwaliCare' ? <div className={styles.homecare__pricing}>
      <p className={`${styles.pricing__price} ${styles.padding_top}`}>{formatNumber(props.plan.price)} <span className={styles.pricing__duration}>/ year</span></p>
    </div> : <div className={styles.homecare__pricing}>
      {props.plan.mrp > props.plan.price && <p className={styles.pricing__duration__line__through}>{formatNumber(props.plan.mrp)}</p>}
      <p className={`${styles.pricing__price} ${props.plan.mrp <= props.plan.price ? styles.padding_top : null}`}> {formatNumber(props.plan.price)}</p>
    </div>
    ) : (
    <div className={styles.homecare__pricing}>
      <p className={styles.pricing__hcp_quote}>Need a quote?</p>
    </div>
    )}
    <div className={styles.homecare_plan__card_cta}>
      <Button
        text={getButtonText(props.plan)}
        onClick={() => {
          addToCart(props.plan)
        }}
        variant="primary"
        className={styles.homecare_card__cta_button}
      />
    </div>
  </div>;

  return (
    <div className={styles.homecare_plan__card_container} display-multiple-plans={props.small_screen || props.noOfPlans > 1 ? "true" : "false" }>
      {title}
      {image}
      {features}
      {priceAndCta}
    </div>
  )
}

const mapStateToProps = (state:any) => {
  return {
    cart: state.cart,
  }
}
const mapDispatchToProps = (dispatch:any) => {
  return {
    addToCart: (data:any) => dispatch(addToCart(data)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(HomecareCard);
